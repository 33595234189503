import React, { Component } from 'react';
import moment from 'moment';
import Markup from '../../form/Markup';
import ThumbsUpIcon from '../../../../public/icons/emojis/thumbs-up.svg';
import ClapIcon from '../../../../public/icons/emojis/clap.svg';
import HeartIcon from '../../../../public/icons/emojis/heart.svg';
import Avatar from '../../Avatar';
import UserList from './../user/UserList';
import Popover2 from '../../Popover2';
import Util from '../../../util';
import ReactionType from '../../../data/enums/ReactionType';
import debounce from 'lodash/debounce';
import ShoutoutService from '../../../services/ShoutoutService';
import Analytics from '../../../data/Analytics';

function getPopoverParent(node) {
	try {
		return node.parentElement.parentElement.parentElement.parentElement;
	} catch {
		return node.parentElement;
	}
}

const EmoteContent = React.memo(({ reactions }) => (
	<UserList size="small" users={reactions?.map((r) => r.user)} />
));

const Reaction = (props) => (
	<Popover2
		placement="bottom"
		overlayClassName="user-list-menu"
		content={
			<EmoteContent
				reactions={props.shoutout.emote_shoutout?.filter(
					(item) => item.emote === props.id
				)}
			/>
		}
		disabled={
			props.loading ||
			props.shoutout.emote_shoutout?.filter((item) => item.emote === props.id)
				.length === 0
		}
		getParent={getPopoverParent}
	>
		<div
			className={`shoutout-card-reaction ${props.color}-color ${
				props.shoutout.emote_stats?.self_stats?.includes(props.id)
					? 'active'
					: ''
			}`}
			onClick={(e) => {
				if (props.loading) return;
				if (e.stopPropagation) e.stopPropagation();
				props.saveEmote(props.shoutout.id, props.id);
			}}
		>
			<div className="shoutout-card-icon">{props.icon}</div>
			{props.count > 0 && <span>({props.count})</span>}
		</div>
	</Popover2>
);

const ShoutoutReactions = (props) => (
	<div className="shoutout-card-reactions">
		<Reaction
			{...props}
			id={ReactionType.THUMBS_UP}
			color="blue"
			count={props.shoutout.emote_stats?.thumbs_up_count}
			icon={<ThumbsUpIcon />}
		/>

		<Reaction
			{...props}
			id={ReactionType.CLAP}
			color="orange"
			count={props.shoutout.emote_stats?.clap_count}
			icon={<ClapIcon className="stroke" />}
		/>

		<Reaction
			{...props}
			id={ReactionType.HEART}
			color="red"
			count={props.shoutout.emote_stats?.heart_count}
			icon={<HeartIcon />}
		/>
	</div>
);

class ShoutoutCard extends Component {
	state = {
		submittingReactions: [],
	};

	saveEmote = debounce((id, type) => {
		const { shoutout, onReactionGiven } = this.props;
		const { submittingReactions } = this.state;
		if (submittingReactions.includes(type)) return;

		const isAdd = !shoutout.emote_stats?.self_stats.includes(type);

		this.changeShoutoutObject(shoutout, id, type, isAdd); // For shoutout dialog
		this.forceUpdate();
		onReactionGiven && onReactionGiven();

		this.setState({ submittingReactions: [...submittingReactions, type] });
		ShoutoutService.reaction(id, type)
			.then(() =>
				Analytics.event(Analytics.Event.SHOUTOUT_REACTION, {
					event: isAdd ? 'add' : 'remove',
					shoutout: id,
					reaction: ReactionType.toText(type),
				})
			)
			.catch(() => {
				this.changeShoutoutObject(shoutout, id, type, false);
				this.forceUpdate();
				onReactionGiven && onReactionGiven();
			})
			.finally(() =>
				this.setState({
					submittingReactions: submittingReactions.filter((r) => r !== type),
				})
			);
	}, 100);

	changeShoutoutObject(shoutout, id, type, add) {
		const { user } = this.props;

		if (!shoutout.emote_shoutout) shoutout.emote_shoutout = [];
		if (!shoutout.emote_stats) shoutout.emote_stats = {};
		if (!shoutout.emote_stats.self_stats) shoutout.emote_stats.self_stats = [];

		if (shoutout.id === id) {
			let added = false;
			if (type === ReactionType.THUMBS_UP) {
				if (
					!shoutout.emote_stats?.self_stats.includes(ReactionType.THUMBS_UP)
				) {
					if (shoutout.emote_stats.thumbs_up_count)
						shoutout.emote_stats.thumbs_up_count++;
					else shoutout.emote_stats.thumbs_up_count = 1;
					shoutout.emote_stats.self_stats.push(ReactionType.THUMBS_UP);
				} else if (!add) {
					shoutout.emote_stats.thumbs_up_count--;
				} else {
					added = true;
				}
			} else if (type === ReactionType.CLAP) {
				if (!shoutout.emote_stats?.self_stats.includes(ReactionType.CLAP)) {
					if (shoutout.emote_stats.clap_count)
						shoutout.emote_stats.clap_count++;
					else shoutout.emote_stats.clap_count = 1;
					shoutout.emote_stats.self_stats.push(ReactionType.CLAP);
				} else if (!add) {
					shoutout.emote_stats.clap_count--;
				} else {
					added = true;
				}
			} else if (type === ReactionType.HEART) {
				if (!shoutout.emote_stats?.self_stats.includes(ReactionType.HEART)) {
					if (shoutout.emote_stats.heart_count)
						shoutout.emote_stats.heart_count++;
					else shoutout.emote_stats.heart_count = 1;
					shoutout.emote_stats.self_stats.push(ReactionType.HEART);
				} else if (!add) {
					shoutout.emote_stats.heart_count--;
				} else {
					added = true;
				}
			}
			if (add) {
				if (!added) {
					shoutout.emote_shoutout = shoutout.emote_shoutout.concat({
						emote: type,
						user: user,
					});
				}
			} else {
				shoutout.emote_shoutout = shoutout.emote_shoutout.filter(
					(r) => r.user.id === user.id && r.emote !== type
				);
				shoutout.emote_stats.self_stats =
					shoutout.emote_stats.self_stats.filter((r) => r !== type) || [];
			}
		}
	}

	renderImages() {
		const { shoutout } = this.props;
		return (
			<Popover2
				placement="bottom"
				overlayClassName="UserListMenu"
				className="AppSider-list-popover shoutout-card-user-images"
				content={<UserList users={shoutout.users} size="small" />}
				disabled={shoutout.users.length <= 2}
				getParent={getPopoverParent}
			>
				{shoutout.users.length > 0 &&
					shoutout.users
						.slice(0, 3)
						.reverse()
						.map((item, index) => {
							return index <= 2 ? (
								<div key={index} className="shoutout-card-title-image">
									<Avatar
										src={item.image}
										size={40}
										name={item.name || item.email}
									/>
								</div>
							) : null;
						})}
			</Popover2>
		);
	}

	render() {
		const { user, shoutout, onClick } = this.props;

		return (
			<div
				className="shoutout-card"
				ref={this.props.customRef}
				onClick={onClick ? () => onClick(shoutout) : undefined}
			>
				<div className="shoutout-card-content">
					<div className="shoutout-card-header">
						{this.renderImages()}
						<div className="shoutout-card-title-container">
							<div className="shoutout-card-title">
								{shoutout.users.length === 1 ? (
									<>
										{user?.id === shoutout.users[0].id
											? 'You'
											: shoutout.users[0].name || shoutout.users[0].email}
										<span className="shoutout-card-title-mini">
											{' '}
											received a shoutout.
										</span>
									</>
								) : null}
								{shoutout.users.length === 2 ? (
									<>
										{user?.id === shoutout.users[0].id
											? 'You'
											: shoutout.users[0].name || shoutout.users[0].email}
										{' and '}
										{user?.id === shoutout.users[1].id
											? 'You'
											: shoutout.users[1].name || shoutout.users[1].email}
										<span className="shoutout-card-title-mini">
											{' '}
											received a shoutout.
										</span>
									</>
								) : null}
								{shoutout.users.length > 2 ? (
									<>
										{user?.id === shoutout.users[0].id
											? 'You'
											: shoutout.users[0].name || shoutout.users[0].email}
										{' and '}
										{shoutout.users.length - 1}
										{' others'}
										<span className="shoutout-card-title-mini">
											{' '}
											received a shoutout.
										</span>
									</>
								) : null}
							</div>
							<div className="shoutout-card-title-created-at">
								{moment(shoutout.createdAt).format('LT | MMM DD, YYYY')}
							</div>
						</div>
					</div>

					<div className="shoutout-card-body">
						<Markup
							className="shoutout-card-text"
							html={Util.convertShoutoutTagsToText(shoutout.shoutout)}
						/>

						<div className="shoutout-card-body-employee">
							<Avatar src={shoutout.sender.image} />
							<span>{shoutout.sender.name}</span>
						</div>
					</div>
				</div>

				<ShoutoutReactions shoutout={shoutout} saveEmote={this.saveEmote} />
			</div>
		);
	}
}

export default ShoutoutCard;

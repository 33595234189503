import React from 'react';

export default function Markup({ html, className }) {
	return (
		<div
			className={className}
			dangerouslySetInnerHTML={{
				__html: html,
			}}
		/>
	);
}

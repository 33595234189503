import { Component, createRef } from 'react';
import UserSuggestionList from './UserSuggestionList';
import SelectUser from '../SelectUser';
import { RefreshCw } from 'react-feather';
import Spinner from '../../../Spinner';
import { Input } from 'antd';
import { HtmlButton } from '../../../HtmlButton';
import Util from '../../../../util';
import AccessRequired from '../../../AccessRequired';
import EventBus from '../../../../util/EventBus';

class UserSuggestions extends Component {
	constructor(props) {
		super(props);
		const suggestions = this.getFilteredSuggestions(props.suggestions);
		this.state = {
			suggestions: suggestions?.slice(
				0,
				props.maxSuggestions || suggestions?.length
			),
			allSuggestions: suggestions,
		};

		this.carouselRef = createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.suggestions?.length && this.props.suggestions?.length) {
			const suggestions = this.getFilteredSuggestions(this.props.suggestions);
			this.setState({
				suggestions: suggestions?.slice(
					0,
					this.props.maxSuggestions || this.props.suggestions?.length
				),
				allSuggestions: suggestions,
			});
		}
	}

	getFilteredSuggestions(suggestions) {
		return this.props.filterSuggestions
			? this.props.filterSuggestions(suggestions)
			: suggestions;
	}

	onUserClick = (user) => this.props.onUserSelected(user);

	onCustomUserSelected(user) {
		const { selectionMode, onUserSelected } = this.props;
		if (onUserSelected) onUserSelected(user);

		if (!selectionMode) return;

		let { suggestions } = this.state;
		if (!suggestions) suggestions = [];
		const exists = suggestions.find((c) => c.email === user.email);
		if (!exists) suggestions.unshift(user);

		this.setState({ suggestions });
	}

	/**
	 * Only select if it's not present in original suggestions
	 * or use the same object if user with same email already exists in suggestions
	 */
	onCustomEmailAdd = () => {
		const { customEmail, suggestions } = this.state;
		const {
			user,
			selectionMode,
			onUserSelected,
			onCustomEmailExisting,
		} = this.props;
		if (!Util.isValidEmail(customEmail) || user?.email === customEmail) return;

		const found = suggestions.find((i) => i.email === customEmail);
		let selected = false;
		if (found) {
			selectionMode &&
				this.setState({
					customEmail: '',
					suggestions: [
						found,
						...suggestions.filter((item) => item.email !== customEmail),
					],
				});
			selected = true;
		} else {
			const found = this.props.suggestions?.find(
				(i) => i.email === customEmail
			);
			if (!found) {
				selectionMode &&
					this.setState({
						customEmail: '',
						suggestions: [
							{ email: customEmail },
							...suggestions.filter((item) => item.email !== customEmail),
						],
					});
				selected = true;
			} else {
				onCustomEmailExisting && onCustomEmailExisting({ email: customEmail });
			}
		}

		if (selected) {
			onUserSelected({ email: customEmail });
			this.carouselRef?.current?.goTo(0);
		}
	};

	renderServerSuggestions() {
		const {
			refreshSuggestions,
			getButtonText,
			showRefresh,
			cardUI,
			isSelected,
			isDisabled,
			selectionMode,
			maxSuggestions,
			dontShowDropDown,
			selectionCount,
			loading,
			clearSelection,
			carousel,
			showSearch,
		} = this.props;
		const { suggestions, allSuggestions } = this.state;

		return (
			<>
				{loading ? (
					<Spinner size="large" />
				) : suggestions?.length ? (
					<>
						{showRefresh && (
							<div className="UserSuggestions-hint">
								<RefreshCw onClick={refreshSuggestions} /> Here are some
								suggestions from your calendar!
							</div>
						)}
						{selectionMode && (
							<div
								className="valigned"
								style={{ justifyContent: 'space-between', marginBottom: 10 }}
							>
								<p className="UserSuggestions-count">
									Selected{' '}
									<span className="font-black color-secondary">
										({selectionCount || 0})
									</span>
								</p>
								{selectionCount > 1 && (
									<HtmlButton type="text" onClick={clearSelection}>
										Clear
									</HtmlButton>
								)}
							</div>
						)}
						<UserSuggestionList
							ref={this.carouselRef}
							carousel={carousel}
							maxSuggestions={maxSuggestions}
							users={suggestions}
							getButtonText={getButtonText}
							isSelected={isSelected}
							isDisabled={isDisabled}
							cardUI={cardUI || carousel}
							onClick={(user) => this.onUserClick(user)}
							selectionMode={selectionMode}
						/>
					</>
				) : (
					<div className="UserSuggestions-empty">No suggestions found</div>
				)}

				{showSearch &&
					!loading &&
					!dontShowDropDown &&
					allSuggestions?.length > 0 && (
						<div className="UserConnections-custom">
							<SelectUser
								users={allSuggestions}
								placeholder="Dont see them in the list? Search here..."
								onSelect={(user) => this.onCustomUserSelected(user)}
							/>
						</div>
					)}
			</>
		);
	}

	renderEmailInput() {
		const { customEmail } = this.state;
		const { getButtonText } = this.props;

		const disabledButton = !Util.isValidEmail(customEmail);

		return (
			<div className="UserConnections-input">
				<div className="PeerSuggestions-hint">
					Have someone else in mind? Enter their email below and click Select to
					confirm.
				</div>
				<div className="UserConnections-input-container">
					<Input
						value={customEmail}
						size="large"
						placeholder="Enter email address..."
						onChange={(e) => this.setState({ customEmail: e.target.value })}
					/>
					<HtmlButton
						type="minimal primary"
						disabled={disabledButton}
						onClick={this.onCustomEmailAdd}
					>
						{getButtonText ? getButtonText() : 'Select'}
					</HtmlButton>
				</div>
			</div>
		);
	}

	render() {
		const { allowCustom, loading, needAccessGoogle } = this.props;

		return (
			<div className="UserSuggestions">
				{needAccessGoogle ? (
					<AccessRequired
						onAccessGiven={() =>
							EventBus.dispatch(EventBus.Event.ACCESS_GIVEN, {
								service: 'google',
							})
						}
					/>
				) : (
					<>
						{this.renderServerSuggestions()}
						{!loading && allowCustom && this.renderEmailInput()}
					</>
				)}
			</div>
		);
	}
}

export default UserSuggestions;

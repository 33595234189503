import { forwardRef } from 'react';
import UserSuggestionListCarousel from './UserSuggestionListCarousel';
import UserSuggestionItem from './UserSuggestionItem';

const UserSuggestionList = (
	{
		users,
		getButtonText,
		isDisabled,
		onClick,
		isSelected,
		cardUI,
		selectionMode,
		maxSuggestions,
		carousel,
	},
	carouselRef
) =>
	carousel ? (
		<UserSuggestionListCarousel
			users={users}
			getButtonText={getButtonText}
			isDisabled={isDisabled}
			onClick={onClick}
			isSelected={isSelected}
			cardUI={cardUI}
			selectionMode={selectionMode}
			maxSuggestions={maxSuggestions}
			ref={carouselRef}
		/>
	) : (
		<div className={`UserSuggestionList ${cardUI ? 'card-ui' : ''}`}>
			{users?.slice(0, maxSuggestions || users?.length).map((user, index) => (
				<UserSuggestionItem
					key={index}
					user={user}
					cardUI={cardUI}
					onClick={onClick}
					selectionMode={selectionMode}
					getButtonText={getButtonText}
					selected={isSelected && isSelected(user)}
					disabled={isDisabled && isDisabled(user)}
				/>
			))}
		</div>
	);

export default forwardRef(UserSuggestionList);

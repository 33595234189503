import React from 'react';
import LockIcon from '../../public/icons/lock.svg';

export default function PrivacyInfo({
	isPublic,
	title,
	message,
	border = true,
	style,
	lockPosition,
	reversed,
}) {
	return (
		<div
			className={`privacy-container ${isPublic ? ' public' : ''} ${
				border ? 'bordered' : ''
			} ${reversed ? 'reversed' : ''}`}
		>
			{title && <div className="privacy-container-text">{title}</div>}
			<div className="privacy-container-content">
				{lockPosition === 'left' && (
					<div className="privacy-container-icon left">
						<LockIcon />
					</div>
				)}
				<div className="privacy-container-text">{message}</div>
				{lockPosition !== 'left' && (
					<div className="privacy-container-icon">
						<LockIcon />
					</div>
				)}
			</div>
		</div>
	);
}

import { useEffect, useState } from 'react';
import UserSuggestions from './index';
import { HtmlButton } from '../../../HtmlButton';
import PrivacyInfo from '../../../PrivacyInfo';
import { useCalendarSuggestions } from '../../../hoc/withCalendarSuggestions';
import { useSuggestions } from '../../../hoc/withSuggestions';
import { useUser } from '../../../hoc/withUser';

const MIN_LIMIT = 1;
const MAX_LIMIT = 0;

function UserSuggestionsDialogView({
	subtitle,
	selectionMode,
	hidePrivacyInfo = false,
	buttonText,
	submitButtonText = 'Confirm',
	onSubmit,
	onSelect,
	filterSuggestions,
	maxSuggestions = 12,
	maxSelection = MAX_LIMIT,
	className = '',
	preSelected,
	loading,
	cardUI = false,
	carousel = true,
	calendar,
	allSources,
	filterCalendarUsers,
	showFooter = true,
	allowCustom = true,
	onCustomEmailExisting,
}) {
	const [selected, setSelected] = useState([]);
	const [init, setInit] = useState(false);
	const { user } = useUser();

	/** Fetch suggestions based on the provider */
	const suggestionData =
		!allSources && calendar
			? useCalendarSuggestions(filterCalendarUsers)
			: useSuggestions({ calendar: allSources });
	let suggestions, fetchingSuggestions;
	if (calendar) {
		suggestions = suggestionData.calendarSuggestions;
		fetchingSuggestions = suggestionData.fetchingSuggestions;
	} else {
		suggestions = suggestionData.suggestions;
		fetchingSuggestions = suggestionData.fetchingSuggestions;
	}

	useEffect(() => {
		if (!init && !fetchingSuggestions && suggestions) {
			const filtered = filterSuggestions
				? filterSuggestions(suggestions)
				: suggestions;
			if (preSelected === 'all') {
				setSelected(filtered);
			} else if (typeof preSelected === 'number' && preSelected) {
				setSelected(filtered.slice(0, preSelected));
			}
			setInit(true);
		}
	}, [suggestions]);

	const onUserSelected = (user) => {
		if (!selectionMode) {
			return onSelect && onSelect(user);
		}

		const users = [];
		let exists = false;

		for (const mUser of selected) {
			if (mUser.email === user.email) {
				exists = true;
			} else {
				users.push(mUser);
			}
		}

		if (!exists && (maxSelection === 0 || users.length < maxSelection)) {
			users.push(user);
		}
		setSelected(users);
	};

	const isUserSelected = (user) => {
		return !!selected.find((s) => s.email === user?.email);
	};

	const clearSelection = () => setSelected([]);

	const needAccessGoogle =
		suggestionData?.needAccessGoogle ||
		suggestionData?.access?.needAccessGoogle;

	return (
		<div className={`user-suggestions-dialog-view ${className}`}>
			{subtitle && <div className="dialog-subtitle">{subtitle}</div>}

			<UserSuggestions
				user={user}
				carousel={carousel}
				suggestions={suggestions}
				showRefresh={false}
				allowCustom={allowCustom}
				cardUI={cardUI}
				refreshSuggestions={undefined}
				onUserSelected={onUserSelected}
				isSelected={isUserSelected}
				filterSuggestions={filterSuggestions}
				getButtonText={
					selectionMode
						? (user) => (isUserSelected(user) ? 'Remove' : 'Select')
						: buttonText
				}
				maxSelection={maxSelection}
				maxSuggestions={maxSuggestions}
				selectionMode={selectionMode}
				selectionCount={selected?.length}
				loading={fetchingSuggestions}
				clearSelection={clearSelection}
				needAccessGoogle={calendar && needAccessGoogle}
				onCustomEmailExisting={onCustomEmailExisting}
				showSearch={false}
			/>

			{showFooter && !fetchingSuggestions && (!calendar || !needAccessGoogle) && (
				<div
					className={`dialog-footer ${!hidePrivacyInfo ? 'between' : 'right'}`}
				>
					{!hidePrivacyInfo && (
						<PrivacyInfo
							reversed
							border={false}
							message="Private between you and the selected people."
						/>
					)}
					<HtmlButton
						disabled={selected.length < MIN_LIMIT}
						onClick={() => onSubmit(selected)}
						loading={loading}
						className="no-flex-shrink"
					>
						{submitButtonText}
					</HtmlButton>
				</div>
			)}
		</div>
	);
}

export default UserSuggestionsDialogView;

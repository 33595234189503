import { http } from '../util/http';

export default class BreakingBreadService {
	static getDailyQuestion(url) {
		const link = '/api/breaking-bread/daily-question';
		return url ? link : http.get(link);
	}

	static submitAnswer(id, body) {
		return http.post('/api/breaking-bread', { id, ...body });
	}
}

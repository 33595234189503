const ReactionType = {
	THUMBS_UP: 1,
	CLAP: 2,
	HEART: 3,

	toText(reaction) {
		switch (reaction) {
			case ReactionType.THUMBS_UP:
				return 'thumbs_up';
			case ReactionType.CLAP:
				return 'clap';
			case ReactionType.HEART:
				return 'heart';
			default:
				throw Error('not defined');
		}
	},
};

export default ReactionType;

import { useState } from 'react';
import SidebarCard from '../../SidebarCard';
import Quote from '../../../../public/icons/quote.svg';
import Spinner from '../../Spinner';
import { HtmlButton } from '../../HtmlButton';
import BreakingBreadSelectDialog from './BreakingBreadSelectDialog';
import UserCard from '../user/UserCard';
import { Skeleton } from 'antd';

function DummyView({ title }) {
	return (
		<SidebarCard className="simple" title={title}>
			<div style={{ height: 5 }} />
			<Skeleton paragraph={{ rows: 2 }} title={false} />
			<div style={{ height: 10 }} />
			<Skeleton paragraph={{ rows: '100%' }} title={false} />
		</SidebarCard>
	);
}

function BreakingBreadDailyCard({
	question,
	fetchingQuestion,
	title = 'Daily Breaking Bread',
	dialogTitle = 'Select Users',
	buttonText = 'Select From List',
	skeleton,
}) {
	const [selectDialogVisible, setSelectDialogVisible] = useState(false);

	if (!fetchingQuestion && !question) return null;
	else if (skeleton) return <DummyView title={title} />;
	return (
		<SidebarCard className="simple daily-breaking-bread-card" title={title}>
			{fetchingQuestion ? (
				<Spinner />
			) : (
				<>
					<div
						className="daily-breaking-bread-body"
						style={{ marginBottom: 30, marginTop: 30 }}
					>
						<Quote />
						<p
							className="daily-question bold body-large"
							style={{ marginBottom: 30 }}
						>
							{question.question}
						</p>
					</div>
					{question.answer && question.answer.tagged?.length ? (
						<div className="daily-breaking-bread-response">
							<span style={{ color: 'var(--text-color-light)' }}>
								Your Response
							</span>
							{question.answer.tagged?.map((user, index) => (
								<UserCard user={user} key={index} />
							))}
						</div>
					) : (
						<HtmlButton
							type="minimal"
							color="secondary"
							className="w-100 extra-bold uppercase"
							size="small"
							onClick={() => setSelectDialogVisible(true)}
						>
							{buttonText}
						</HtmlButton>
					)}
				</>
			)}

			<BreakingBreadSelectDialog
				title={dialogTitle}
				visible={selectDialogVisible}
				question={question}
				hide={() => setSelectDialogVisible(false)}
			/>
		</SidebarCard>
	);
}

export default BreakingBreadDailyCard;

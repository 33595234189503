import useSWR from 'swr';
import UserService from '../../services/UserService';
import { useUser } from './withUser';
import Constants from '../../util/Constants';

export function useCompanySuggestions() {
	const { user } = useUser();
	const { data: companySuggestions, error } = useSWR(
		user ? UserService.getCompanySuggestions(true) : null,
		undefined,
		Constants.SWR_CONFIG
	);
	return {
		companySuggestions,
		error,
		fetchingSuggestions: !companySuggestions && !error,
	};
}

export function withCompanySuggestions(WrappedComponent) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const data = useCompanySuggestions();
		return <WrappedComponent {...props} {...data} />;
	};
}

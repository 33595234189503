import { useRef, useState } from 'react';
import { Select } from 'antd';
import UserCard from './UserCard';

export default function SelectUser({
	users,
	selected,
	onSelect,
	placeholder,
	multiple,
	closeOnSelect = true,
	size = '',
}) {
	const [input, setInput] = useState(undefined);
	const select = useRef(null);

	function onSelected(item, option) {
		if (onSelect) {
			if (!multiple) onSelect(option.children.props.user);
			else onSelect(item);
		}
		setInput({ value: undefined, label: placeholder });

		if (closeOnSelect) select && select.current && select.current.blur();
	}

	function filter(input, option) {
		return option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	}

	return (
		<Select
			ref={select}
			showSearch
			value={multiple ? selected : input}
			labelInValue={!multiple}
			onSelect={!multiple ? onSelected : null}
			onChange={multiple ? onSelected : null}
			placeholder={placeholder}
			className={`select-user ${multiple ? 'multiple' : ''}`}
			size="large"
			optionLabelProp={multiple ? 'name' : null}
			mode={multiple ? 'multiple' : null}
			filterOption={filter}
			autoClearSearchValue={true}
			defaultValue={undefined}
		>
			{users.map((item, index) => (
				<Select.Option
					key={index}
					value={multiple ? item.uuid : `${item.name} ${index}`}
					name={`${item.name || ''} ${item.email}`}
				>
					<UserCard user={item} key={index} size={size} showEmail />
				</Select.Option>
			))}
		</Select>
	);
}

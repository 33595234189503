import Dialog from '../../Dialog';
import UserSuggestionsDialogView from '../user/suggestions/UserSuggestionsDialogView';
import BreakingBreadService from '../../../services/BreakingBreadService';
import Notification from '../../../util/Notification';

function BreakingBreadSelectDialog({
	visible,
	hide,
	question,
	title = 'Select Users',
}) {
	const onSubmit = (selected) => {
		BreakingBreadService.submitAnswer(question.id, {
			userEmails: selected.map((u) => u.email),
		})
			.then(() => {
				Notification.success('Your response has been recorded');
				window.location.reload();
			})
			.catch(() =>
				Notification.error('Failed to record your response. Please try again.')
			);
	};

	return (
		<Dialog
			visible={visible}
			hide={hide}
			size="medium"
			title={title}
			className="no-body-padding"
		>
			<UserSuggestionsDialogView
				subtitle={question?.question ? `"${question.question}"` : undefined}
				selectionMode={true}
				hide={hide}
				onSubmit={onSubmit}
				maxSelection={1}
			/>
		</Dialog>
	);
}

export default BreakingBreadSelectDialog;

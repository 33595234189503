import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Util from '../../../util';
import Analytics from '../../../data/Analytics';
import ShoutoutCard from './ShoutoutCard';
import ShoutoutDialog from './ShoutoutDialog';

function Shoutouts({ shoutouts, user }) {
	const [selectedShoutout, setSelectedShoutout] = useState();
	const router = useRouter();

	useEffect(() => {
		const shoutoutId = router.query?.shoutout;
		if (
			shoutoutId &&
			shoutouts?.length &&
			selectedShoutout?.id !== shoutoutId
		) {
			const shoutout = shoutouts?.find((s) => s.id === shoutoutId);
			shoutout && setSelectedShoutout(shoutout);
		}
	}, [shoutouts, router.query]);

	const onClick = (shoutout) => {
		setSelectedShoutout(shoutout);
		Analytics.event(Analytics.Event.SHOUTOUT_CLICKED, {
			name: 'shoutout_clicked',
		});
		Util.updateQuery({ shoutout: shoutout.id });
	};

	if (!shoutouts?.length) return <div style={{ minHeight: 200 }} />;
	return (
		<>
			<section className="shoutout-list">
				{shoutouts.map((shoutout, index) => (
					<ShoutoutCard
						key={index}
						user={user}
						shoutout={shoutout}
						onClick={onClick}
					/>
				))}
			</section>

			<ShoutoutDialog
				user={user}
				visible={!!selectedShoutout}
				shoutout={selectedShoutout}
				hide={() => {
					setSelectedShoutout(null);
					Util.updateQuery({ shoutout: undefined });
				}}
			/>
		</>
	);
}

export default Shoutouts;

import { Check } from 'react-feather';
import Avatar from '../../../Avatar';
import { HtmlButton } from '../../../HtmlButton';

export const USER_SUGGESTION_ITEM_WIDTH = 500;
export const USER_SUGGESTION_ITEM_CARD_WIDTH = 180;

function UserSuggestionItem({
	user,
	cardUI,
	selectionMode,
	onClick,
	getButtonText,
	selected,
	disabled,
}) {
	return (
		<div
			className={`UserSuggestionCard ${cardUI ? 'card-ui' : ''} ${
				selectionMode ? 'selection-mode' : ''
			} ${selected ? 'selected' : ''}`}
			onClick={cardUI ? () => onClick(user) : undefined}
		>
			{cardUI && selectionMode && (
				<div className="UserSuggestionCard-radio">
					<div
						className={`UserSuggestionCard-radio-boundary ${
							selected ? 'selected' : ''
						}`}
					>
						{selected && <Check />}
					</div>
				</div>
			)}
			<div className="UserSuggestionCard-image">
				<Avatar src={user.image} name={user.name || user.email} size={36} />
			</div>
			<div className="UserSuggestionCard-details">
				<div className="UserSuggestionCard-name">{user.name || user.email}</div>
			</div>
			{!cardUI && (
				<HtmlButton
					type="minimal"
					size="small"
					color="primary"
					disabled={disabled}
					className="UserSuggestionCard-button"
					onClick={() => onClick(user)}
				>
					{getButtonText(user)}
				</HtmlButton>
			)}
		</div>
	);
}

export default UserSuggestionItem;

import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import Avatar from '../../Avatar';

export const MentionField = (props) => (
	<MentionsInput
		rows={3}
		value={props.value}
		className={`shoutout-input-fields ${
			props.value.trim().length === 0 && props.showValidation
				? 'shoutout-input-field-red'
				: ''
		}`}
		placeholder={`${props.placeholder}`}
		onChange={(e) => props.onTextChange(e)}
		singleLine={props.singleLine}
		inputRef={props.inputRef}
	>
		<Mention
			trigger="@"
			data={props.suggestions || null}
			appendSpaceOnAdd={true}
			markup={'@[:__display__:](:__id__:)'}
			displayTransform={(id, display) => `@${display}`}
			renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
				<div
					key={index}
					className={`shoutout-input-mention-dropdown-item ${
						focused ? 'focused' : ''
					}`}
				>
					<div className="mention-user">
						<Avatar src={entry.image} name={entry.display} />
						<span className="mention-user-name">{entry.display}</span>
					</div>
				</div>
			)}
		/>
	</MentionsInput>
);

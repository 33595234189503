import Avatar from './Avatar';
import Grid from './Grid';

export default function UserTag({ user, onClick }) {
	const name = user.name || user.email;
	return (
		<div
			className={`user-tag${onClick ? ' clickable' : ''}`}
			onClick={onClick ? () => onClick(user) : undefined}
		>
			<Avatar src={user.image} name={name} size={48} />
			<span className="user-name bold body-small">{name}</span>
		</div>
	);
}

export function UserTags({ users, onClick }) {
	return (
		<Grid
			padding={6}
			minWidth={170}
			items={users}
			render={(item, index) => (
				<UserTag user={item} key={index} onClick={onClick} />
			)}
		/>
	);
}

import useSWR from 'swr';
import BreakingBreadService from '../../services/BreakingBreadService';
import Config from '../../data/Config';
import { useUser } from './withUser';
import Constants from '../../util/Constants';

/**
 * Daily Breaking Bread Question
 */
export function useBreakingBreadQuestion() {
	if (!Config.DAILY_QUESTION_ENABLED) return {};

	const { user } = useUser();
	let { data: question, error } = useSWR(
		user ? BreakingBreadService.getDailyQuestion(true) : null,
		undefined,
		Constants.SWR_CONFIG
	);
	if (typeof question === 'string') {
		error = question;
		question = null;
	}

	return { question, error, fetchingQuestion: !question && !error };
}

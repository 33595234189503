import React from 'react';
import Avatar from '../../Avatar';

export default function UserCard({
	user,
	showEmail = false,
	size = '',
	onClick,
	selected,
}) {
	const name = user.name || user.email;
	return (
		<div
			className={`user-card ${size} ${selected ? 'selected' : ''} ${
				onClick ? 'clickable' : ''
			}`}
			onClick={onClick ? () => onClick(user) : null}
		>
			<Avatar src={user.image} size={36} name={name} />
			<div className="user-card-details">
				<div className="user-card-name">{name}</div>
				{showEmail && user.email && (
					<div className="user-card-email">{user.email}</div>
				)}
			</div>
		</div>
	);
}

import { HtmlButton } from './HtmlButton';
import GoogleIcon from '../../public/icons/google.svg';

export default function AccessRequired({ onAccessGiven }) {
	return (
		<div className="access-required-container">
			<div className="access-required-wrapper">
				<div className="access-required-title body-large semibold">
					Swanvo would like to access your contacts to give you the best
					colleague suggestions
				</div>
				<div className="access-required-message" style={{ marginTop: 10 }}>
					We will never contact anyone without your permission.
				</div>
				<div
					className="access-required-button-container"
					style={{ marginTop: 30 }}
				>
					<HtmlButton
						color="primary"
						className="access-required-button round colored-icon"
						onClick={onAccessGiven}
					>
						<GoogleIcon style={{ marginRight: 20 }} /> Give access to see your
						workplace friends
					</HtmlButton>
				</div>
			</div>
		</div>
	);
}

import { useEffect, useState } from 'react';
import ShoutoutInput from './ShoutoutInput';
import { UserTags } from '../../UserTag';
import EventBus from '../../../util/EventBus';
import { useProfile } from '../../hoc/withProfile';
import UserService from '../../../services/UserService';
import { HtmlButton } from '../../HtmlButton';
import { useUser } from '../../hoc/withUser';
import { useRouter } from 'next/router';
import Analytics from '../../../data/Analytics';
import GoogleIcon from '../../../../public/icons/google.svg';
import AccountSyncIcon from '../../../../public/icons/account-sync.svg';
import Util from '../../../util';
import ShoutoutSuggestionsDialog from './ShoutoutSuggestionsDialog';

function AccessRequiredView({ laterButtonText, onLaterClick }) {
	return (
		<div className="shoutout-input-access-required">
			<AccountSyncIcon style={{ marginBottom: 10 }} />
			<div
				className="access-required-message body-medium"
				style={{ textAlign: 'center' }}
			>
				Syncing your contacts helps Swanvo give you the best people suggestions.
			</div>

			<div className="valigned centered">
				<HtmlButton
					color="secondary"
					type="filled"
					size="small"
					onClick={() =>
						EventBus.dispatch(EventBus.Event.ACCESS_GIVEN, {
							service: 'google',
						})
					}
				>
					<GoogleIcon style={{ marginRight: 20 }} /> Connect with Google
				</HtmlButton>
			</div>

			<HtmlButton
				color="#3391ff"
				size="small"
				type="text"
				className="hover-underline"
				onClick={onLaterClick}
				style={{
					marginTop: 5,
					color: 'var(--text-color-light)',
					letterSpacing: 0.875,
				}}
			>
				{laterButtonText}
			</HtmlButton>
		</div>
	);
}

function ShoutoutInputCard({
	onSubmitted,
	showSuggestions,
	fetchingSuggestions,
	suggestions,
	suggestionAccess,
}) {
	const { config, mutateConfig, fetchingProfile } = useProfile();
	const { isLoggedIn, user } = useUser();
	const [selectedSuggestions, setSelectedSuggestions] = useState(new Set());
	const [allSuggestionsDialog, setSuggestionsDialog] = useState(false);
	const router = useRouter();

	useEffect(() => {
		EventBus.on(EventBus.Event.SHOUTOUT_USER, (user) => {
			if (user?.email) {
				setSelectedSuggestions((selectedSuggestions) => {
					const newSuggestions = new Set(selectedSuggestions);
					newSuggestions.add(user.email);
					return newSuggestions;
				});
			}
		});
		return () => EventBus.remove(EventBus.Event.SHOUTOUT_USER);
	}, []);

	const updateShoutoutHintHidden = () => {
		const mConfig = { shoutoutInputHintHidden: true };
		UserService.updateConfig({ ...(config || {}), ...mConfig })
			.then(() => mutateConfig(mConfig))
			.then(() =>
				Analytics.event(Analytics.Event.USER_CONFIG, {
					event: 'shoutout_input_hidden',
				})
			)
			.catch(() => {});
	};

	const updateConnectSuggestions = !showSuggestions
		? undefined
		: () => {
				const mConfig = { shoutoutInputPermissionHidden: true };
				UserService.updateConfig({ ...(config || {}), ...mConfig })
					.then(() => mutateConfig(mConfig))
					.then(() =>
						Analytics.event(Analytics.Event.USER_CONFIG, {
							event: 'connect_services_hidden',
						})
					)
					.catch(() => {});
		  };

	const onSuggestionClick = (user) => {
		EventBus.dispatch(EventBus.Event.SHOUTOUT_USER, user);
		Analytics.event(Analytics.Event.INPUT_SUGGESTIONS, { event: 'click' });
	};

	const onShoutoutSubmitted = () => {
		router.push(window.location.pathname + window.location.search);
		onSubmitted && onSubmitted();
	};

	const onSuggestionsDialogClick = () => {
		setSuggestionsDialog(true);
		Analytics.event(Analytics.Event.INPUT_SUGGESTIONS_DIALOG, {
			event: 'opened',
		});
	};

	return (
		<div className="shoutout-input-card">
			<div className="shoutout-input-container">
				<ShoutoutInput
					onSubmitted={onShoutoutSubmitted}
					suggestions={suggestions}
					loading={fetchingSuggestions}
				/>
				{!fetchingProfile && !config?.shoutoutInputHintHidden && (
					<div className="shoutout-input-hint">
						<p className="message body-small semibold italic">
							Note: You can also tag an email address to give a shoutout to your
							friend i.e.{' '}
							<span className="bold">
								“@john@companyname.com is superb to work with”
							</span>
							.
						</p>
						{isLoggedIn && (
							<HtmlButton
								type="text"
								size="small"
								onClick={updateShoutoutHintHidden}
							>
								OK
							</HtmlButton>
						)}
					</div>
				)}
			</div>
			{user &&
				!fetchingProfile &&
				!fetchingSuggestions &&
				(!config?.shoutoutInputPermissionHidden &&
				suggestionAccess?.needAccessGoogle ? (
					<div className="card shoutout-suggestions">
						<AccessRequiredView
							laterButtonText={
								user?.company
									? `Show me who from ${Util.titleCase(
											user?.company
									  )} is using Swanvo.`
									: 'Maybe Later'
							}
							onLaterClick={updateConnectSuggestions}
						/>
					</div>
				) : (
					showSuggestions &&
					suggestions?.length > 0 && (
						<div className="card shoutout-suggestions">
							<div
								className="valigned justify-content-between"
								style={{ marginBottom: 5, marginTop: -10 }}
							>
								<label className="body-small semibold">Suggestions</label>
								{suggestions?.length > 8 && (
									<HtmlButton
										type="text"
										size="small"
										onClick={onSuggestionsDialogClick}
									>
										View All
									</HtmlButton>
								)}
							</div>
							<UserTags
								users={suggestions?.filter(
									(s) => !selectedSuggestions.has(s.email)
								)}
								loading={fetchingSuggestions}
								onClick={onSuggestionClick}
							/>
						</div>
					)
				))}

			{allSuggestionsDialog && (
				<ShoutoutSuggestionsDialog
					selectedSuggestions={selectedSuggestions}
					visible={allSuggestionsDialog}
					hide={() => setSuggestionsDialog(false)}
				/>
			)}
		</div>
	);
}

export default ShoutoutInputCard;

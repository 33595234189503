import React, { useState } from 'react';
import Dialog from '../../Dialog';
import ShoutoutCard from './ShoutoutCard';

export default function ShoutoutDialog({
	shoutout,
	visible,
	hide,
	type,
	loading,
	user,
}) {
	const [value, forceUpdate] = useState(0);

	return (
		<Dialog
			visible={visible}
			hide={hide}
			size="medium"
			showCloseIcon
			className="shoutout-dialog"
			loading={loading}
		>
			<ShoutoutCard
				user={user}
				shoutout={shoutout}
				shoutoutType={type}
				onReactionGiven={() => forceUpdate(value + 1)}
			/>
		</Dialog>
	);
}

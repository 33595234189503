import { useCallback, useEffect, useState, forwardRef } from 'react';
import { Carousel } from 'antd';
import { SizeMe } from 'react-sizeme';

let lastClickTime = 0;

function CarouselGrid(
	{
		render,
		items,
		minWidth,
		rows = 1,
		sections,
		padding = 12,
		emptyMessage,
		animating = true,
	},
	ref
) {
	const [init, setInit] = useState(false);
	const length = items.length;

	useEffect(() => {
		lastClickTime = 0;

		if (ref && !init) {
			setInit(true);
			ref?.current?.goTo(0, true);
		}
	}, [ref, init]);

	function getCarouselSections(size) {
		let total = size.width / (minWidth + padding);
		if (total % 1 >= 0.93) total = Math.ceil(total) * rows;
		else total = parseInt(total.toString()) * rows;

		const sectionIndices = [];
		if (!total) return sectionIndices;

		for (let i = 0; i < length; i++) {
			let sectionTotal = total;
			if (i + (sectionTotal - 1) < length) {
				sectionIndices.push({ start: i, end: i + (sectionTotal - 1) });
				i += sectionTotal - 1;
			} else {
				sectionIndices.push({ start: i, end: length - 1 });
				break;
			}
		}
		if (sectionIndices.length === 0) sectionIndices.push({ start: 0, end: 0 });
		return sections ? sectionIndices.slice(0, sections) : sectionIndices;
	}

	const allowClick = useCallback(() => {
		return !lastClickTime || Date.now() - lastClickTime < 100;
	}, []);

	return (
		<div className={`CarouselGrid ${length === 0 ? 'empty' : ''}`}>
			{length > 0 ? (
				<SizeMe
					refreshRate={200}
					refreshMode={animating ? 'debounce' : 'threshold'}
					children={null}
					render={({ size }) => (
						<Carousel
							draggable
							dotPosition="bottom"
							autoplay={false}
							initialSlide={0}
							ref={ref}
						>
							{getCarouselSections(size).map((section, index) => (
								<div
									key={index}
									onMouseDown={() => (lastClickTime = Date.now())}
								>
									<div
										className="CarouselGrid-section"
										style={{
											display: 'grid',
											gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`,
											gridGap: padding,
										}}
									>
										{items?.slice &&
											items
												.slice(section.start, section.end + 1)
												.map((item, itemIndex) =>
													render(item, index, itemIndex, allowClick)
												)}
									</div>
								</div>
							))}
						</Carousel>
					)}
				/>
			) : (
				emptyMessage && emptyMessage
			)}
		</div>
	);
}

export default forwardRef(CarouselGrid);

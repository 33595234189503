import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Data from '../../data';
import { useUser } from './withUser';
import { getCalendarSuggestions } from '../../store/actions';

export function useCalendarSuggestions(filterUsers) {
	const { user } = useUser();
	const calendarSuggestions = useSelector(Data.getCalendarSuggestions);
	const fetchingSuggestions = useSelector(Data.getSuggestionsFetching);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user && !calendarSuggestions) fetchSuggestions();
	}, [user, calendarSuggestions]);

	function fetchSuggestions(authenticate = false) {
		dispatch(getCalendarSuggestions(user?.email, authenticate));
	}

	function getFilteredSuggestions() {
		const emails = filterUsers?.map((u) => u.email);
		return calendarSuggestions?.filter((c) => !emails?.includes(c.email));
	}

	return {
		calendarSuggestions: filterUsers
			? getFilteredSuggestions()
			: calendarSuggestions,
		fetchingSuggestions,
		needAccessGoogle: !fetchingSuggestions && !calendarSuggestions,
	};
}

export function withCalendarSuggestions(WrappedComponent) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const data = useCalendarSuggestions();
		return <WrappedComponent {...props} {...data} />;
	};
}

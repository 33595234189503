import { useEffect, useState } from 'react';
import { useCompanySuggestions } from './withCompanySuggestions';
import { useCalendarSuggestions } from './withCalendarSuggestions';
import Util from '../../util';

export function useSuggestions({ calendar = true, company = true } = {}) {
	const {
		calendarSuggestions,
		fetchingSuggestions: fetchingCalSuggestions,
		needAccessGoogle,
		fetchSuggestions,
	} = calendar ? useCalendarSuggestions() : {};
	const { companySuggestions, fetchingSuggestions } = company
		? useCompanySuggestions()
		: {};

	const [suggestions, setSuggestions] = useState(null);

	useEffect(() => {
		if (fetchingCalSuggestions || fetchingSuggestions) return;
		if (!company || !calendar) return;

		setSuggestions(mergeSuggestions());
	}, [
		calendarSuggestions,
		companySuggestions,
		fetchingCalSuggestions,
		fetchingSuggestions,
	]);

	function mergeSuggestions() {
		return calendar && company
			? Util.mergeArrays(calendarSuggestions, companySuggestions, 'email')
			: null;
	}

	return {
		suggestions: suggestions
			? suggestions.sort((a, b) => b.count - a.count)
			: company
			? companySuggestions
			: calendarSuggestions,
		fetchingSuggestions: fetchingCalSuggestions || fetchingSuggestions,
		fetchingCalendarSuggestions: fetchingCalSuggestions,
		fetchSuggestions,
		access: { needAccessGoogle },
	};
}

export function withSuggestions(
	WrappedComponent,
	{ calendar = true, company = true } = {}
) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const data = useSuggestions({ calendar, company });
		return <WrappedComponent {...props} {...data} />;
	};
}

import React from 'react';
import UserCard from './UserCard';

export default function UserList({ users, size = 'large', onClick, selected }) {
	if (!users?.length) return null;
	return (
		<div className={`user-list ${size}`}>
			{users.map((item, index) => (
				<UserCard
					user={item}
					key={index}
					size={size}
					onClick={onClick}
					selected={selected && selected.id === item.id}
				/>
			))}
		</div>
	);
}

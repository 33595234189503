import { http } from '../util/http';
import ShoutoutType from '../data/enums/ShoutoutType';

export default class ShoutoutService {
	static sendShoutout(shoutout) {
		return http.post('/api/shoutouts', { shoutout });
	}

	static getShoutout(id) {
		return http.get(`/api/shoutouts/${id}`);
	}

	static reaction(id, reactionType) {
		return http.patch(`/api/shoutouts/${id}/reaction`, { reactionType });
	}

	static getCompanyShoutouts(company) {
		return http.get(`/api/shoutouts/company/${company}`);
	}

	static getUserShoutouts(username, type = ShoutoutType.ALL) {
		return http.get(`/api/shoutouts/user/${username}?type=${type}`);
	}
}

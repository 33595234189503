import { Skeleton } from 'antd';
import NotificationIcon from '../../../../public/icons/notification.svg';
import Util from '../../../util';
import LockFilled from '../../../../public/icons/lock-filled.svg';

export function UnreadShoutoutCard({ count }) {
	return (
		<div className="shoutout-unread-card">
			<NotificationIcon />
			<span>
				You have ({count}) shoutout(s) waiting, to view give someone deserving a
				shoutout!
			</span>
		</div>
	);
}

export default function LockedShoutoutCard({ count }) {
	return (
		<div className="shoutout-locked">
			<Skeleton avatar paragraph={{ rows: 4 }} className="card" active />
			<div className="shoutout-locked-overlay">
				<div className="icon-wrapper">
					<LockFilled />
				</div>
				<p className="message body-medium">
					You have received {count} {Util.plural('shoutout', count)}! Unlock by
					sending someone a shoutout and make their day!
				</p>
			</div>
		</div>
	);
}

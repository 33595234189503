import Dialog from '../../Dialog';
import UserSuggestionsDialogView from '../user/suggestions/UserSuggestionsDialogView';
import EventBus from '../../../util/EventBus';

function ShoutoutSuggestionsDialog({
	visible,
	hide,
	title = 'Choose someone to give a shoutout to!',
	selectedSuggestions,
}) {
	const onSelect = (user) => {
		EventBus.dispatch(EventBus.Event.SHOUTOUT_USER, user);
		hide();
	};

	return (
		<Dialog visible={visible} hide={hide} size="xmedium" title={title}>
			<UserSuggestionsDialogView
				hide={hide}
				onSelect={onSelect}
				maxSelection={1}
				showFooter={false}
				maxSuggestions={0}
				allowCustom={false}
				filterSuggestions={(suggestions) =>
					suggestions?.filter((s) => !selectedSuggestions.has(s.email))
				}
			/>
		</Dialog>
	);
}

export default ShoutoutSuggestionsDialog;

import { useEffect, useState } from 'react';
import ShoutoutIcon from '../../../../public/icons/shoutout.svg';
import { MentionField } from './MentionField';
import { Send as SendIcon } from 'react-feather';
import ShoutoutService from '../../../services/ShoutoutService';
import Util from '../../../util';
import Analytics from '../../../data/Analytics';
import Notification from '../../../util/Notification';
import { useUser } from '../../hoc/withUser';
import { withRouter } from 'next/router';
import Links from '../../../navigation/Links';
import EventBus from '../../../util/EventBus';

function ShoutoutInput({ suggestions = [], onSubmitted, ...props }) {
	const [description, setDescription] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const { isLoggedIn } = useUser();
	let inputRef;

	useEffect(() => {
		inputRef?.focus();

		EventBus.on(EventBus.Event.SHOUTOUT_USER, (user) => {
			if (user?.email) {
				setDescription((description) => {
					if (Util.extractEmails(description).includes(user?.email)) {
						return description;
					} else {
						return `${description ? `${description} ` : ''}@[:${
							user.name || user.email
						}:](:${user.email}:) `;
					}
				});
				inputRef?.focus();
			}
		});

		if (props.router?.query?.uEmail) {
			EventBus.dispatch(EventBus.Event.SHOUTOUT_USER, {
				email: props.router.query.uEmail,
			});
			props.router.push(Util.updateQuery({ uEmail: null }), undefined, {
				shallow: true,
			});
		}

		return () => EventBus.remove(EventBus.Event.SHOUTOUT_USER);
	}, []);

	const onSubmit = () => {
		if (!isLoggedIn) {
			Notification.error('Please login before continuing');

			const router = props.router;
			return router.push(Links.Login(router.asPath || router.pathname));
		}

		if (!submitting && validateDescription()) {
			setSubmitting(true);
			ShoutoutService.sendShoutout(description)
				.then((shoutout) => {
					Analytics.event(Analytics.Event.SHOUTOUT_SUBMITTED, {
						name: 'shoutout_submitted',
					});
					setDescription('');
					Notification.success('Your shoutout has been sent!');
					onSubmitted && onSubmitted(shoutout);
				})
				.catch(() => Notification.error('Failed to send shoutout'))
				.finally(() => setSubmitting(false));
		}
	};

	function isInputValid(str) {
		return str && Util.extractEmails(str);
	}

	function validateDescription() {
		return isInputValid(description.trim()).length > 0;
	}

	const onChangeDescription = (e) => setDescription(e.target.value);

	function parseEmail(description) {
		const emails = Util.extractEmails(description, false);
		for (const email of emails) {
			const user = suggestions?.find((f) => f.id === email);
			if (user) {
				description = description.replace(
					`@${email}`,
					`@[:${user.display || user.id}:](:${user.id}:) `
				);
			}
		}
		return description;
	}

	const disableSubmitButton = submitting || !validateDescription();
	suggestions = suggestions?.map((item) => ({
		id: item.email,
		display: item.name || item.email,
		image: item.image,
	}));

	return (
		<div className="shoutout-input">
			<div className="shoutout-input-content">
				<ShoutoutIcon className="shoutout-icon" />
				<MentionField
					singleLine
					value={description}
					placeholder="Type '@' to tag your peers in a shoutout"
					suggestions={suggestions}
					onTextChange={onChangeDescription}
					inputRef={(ref) => (inputRef = ref)}
				/>
			</div>

			<div
				className={
					'submit-button' + (disableSubmitButton ? ' disabled' : ' enabled')
				}
				onClick={onSubmit}
			>
				<SendIcon />
			</div>
		</div>
	);
}

export default withRouter(ShoutoutInput);

import { http } from '../util/http';

export default class UserService {
	static getSuggestions(url) {
		const link = '/api/calendar-suggestions';
		return url ? link : http.get(link);
	}

	static getCompanySuggestions(url) {
		const link = '/api/company-suggestions';
		return url ? link : http.get(link);
	}

	static getStrongLinks(url) {
		const link = '/api/users/strong-links';
		return url ? link : http.get(link);
	}

	static requestStrongLinks(emails) {
		return http.post('/api/users/strong-links', { emails });
	}

	static getProfile(url) {
		const link = '/api/users/profile';
		return url ? link : http.get(link);
	}

	static updateProfile(body) {
		return http.patch('/api/users/profile', body);
	}

	static updateConfig(config) {
		return UserService.updateProfile({ config });
	}
}

import { forwardRef } from 'react';
import CarouselGrid from '../../../CarouselGrid';
import UserSuggestionItem, {
	USER_SUGGESTION_ITEM_CARD_WIDTH,
	USER_SUGGESTION_ITEM_WIDTH,
} from './UserSuggestionItem';

const UserSuggestionListCarousel = (
	{
		users,
		getButtonText,
		isDisabled,
		onClick,
		isSelected,
		cardUI,
		selectionMode,
		maxSuggestions,
	},
	ref
) => (
	<CarouselGrid
		ref={ref}
		className={`UserSuggestionListCarousel ${cardUI ? 'card-ui' : ''}`}
		minWidth={
			cardUI ? USER_SUGGESTION_ITEM_CARD_WIDTH : USER_SUGGESTION_ITEM_WIDTH
		}
		items={users?.slice(0, maxSuggestions || users?.length)}
		rows={2}
		render={(user, index, itemIndex, clickable) => (
			<UserSuggestionItem
				key={user.id || itemIndex}
				user={user}
				cardUI={cardUI}
				onClick={onClick ? (user) => clickable() && onClick(user) : undefined}
				selectionMode={selectionMode}
				getButtonText={getButtonText}
				selected={isSelected && isSelected(user)}
				disabled={isDisabled && isDisabled(user)}
			/>
		)}
	/>
);

export default forwardRef(UserSuggestionListCarousel);

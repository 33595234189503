import useSWR from 'swr';
import UserService from '../../services/UserService';
import { useUser } from './withUser';
import Constants from '../../util/Constants';

export function useProfile() {
	const { user } = useUser();
	const { data: profile, error, mutate } = useSWR(
		user ? UserService.getProfile(true) : null,
		undefined,
		Constants.SWR_CONFIG
	);

	async function mutateConfig(updatedConfig) {
		if (!profile) return;
		await mutate({ config: { ...profile?.config, ...updatedConfig } });
	}

	return {
		profile,
		config: profile?.config,
		stats: profile?.stats,
		error,
		fetchingProfile: !profile && !error,
		mutateProfile: mutate,
		mutateConfig,
	};
}

export function withProfile(WrappedComponent) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const {
			profile,
			config,
			stats,
			error,
			fetchingProfile,
			mutateProfile,
			mutateConfig,
		} = useProfile();
		return (
			<WrappedComponent
				{...props}
				profile={profile}
				config={config}
				stats={stats}
				fetchingProfile={fetchingProfile}
				mutateProfile={mutateProfile}
				mutateConfig={mutateConfig}
				error={error}
			/>
		);
	};
}
